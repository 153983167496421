import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueRouterMetaTags from '@bachdgvn/vue-router-meta-tags';
import Home from '../views/Home.vue'
import GalleriaEstesa from "@/views/GalleriaEstesa.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "Home - Tri-Do Marmi"
    }
  },
  {
    path: '/galleria',
    name: 'Galleria',
    component: GalleriaEstesa,
    meta: {
      title: "Galleria - Tri-Do Marmi"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach(VueRouterMetaTags.update);

export default router
