
import { Component, Vue } from 'vue-property-decorator';
import { Fancybox, Carousel } from "@fancyapps/ui";

@Component
export default class Chisiamo extends Vue {
  private backgroundCss = { "background-image": `url(${require('@/assets/marble.jpg')})`}
  private albumHash = "aEM1nYr"
  private images = []
  private mainCarousel = null
  created(){
    fetch(`https://api.imgur.com/3/album/${this.albumHash}/images`, {
      method: 'GET',
      headers: {
        Authorization: "Client-ID 62e4df526d38ce3"
      },
      referrerPolicy: 'no-referrer'
    })
        .then(response => response.text())
        .then(result => JSON.parse(result).data.map((i: any) => this.images.push(i.link)))
        .then(() => this.createCarousel())
        .catch(error => console.log('error', error))
  }

  createCarousel(): void{
    this.mainCarousel && this.mainCarousel.destroy()
    this.mainCarousel = new Carousel(document.querySelector("#mainCarousel"), {
      Dots: true,
      slidesPerPage : 1,
      preload: 2,
      friction: 0.8,
      center: true
    })
    Fancybox.destroy()
    Fancybox.bind('[data-fancybox="chisiamo"]', {
      Carousel: {
        on: {
          change: (that) => {
            this.mainCarousel.slideTo(this.mainCarousel.findPageForSlide(that.page));
          },
        },
      },
    });
  }
}
