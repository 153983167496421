
import { Component, Vue } from 'vue-property-decorator';
import {Fancybox} from "@fancyapps/ui";

@Component
export default class GalleriaEstesa extends Vue {
  private backgroundCss = {"background-image": `url(${require('@/assets/marble3.jpg')})`}
  private albums = [
    {
      name: "Arte sacra",
      images: require.context("@/../public/images/Arte sacra").keys().reverse().map(x => `/images/Arte sacra/${x.substring(2)}`),
      open: false
    },
    {
      name: "Camini",
      images: require.context("@/../public/images/Camini").keys().reverse().map(x => `/images/Camini/${x.substring(2)}`),
      open: false
    },
    {
      name: "Lavabi",
      images: require.context("@/../public/images/Lavabi").keys().reverse().map(x => `/images/Lavabi/${x.substring(2)}`),
      open: false
    },
    {
      name: "Numeri civici e targhe",
      images: require.context("@/../public/images/Numeri civici e targhe").keys().reverse().map(x => `/images/Numeri civici e targhe/${x.substring(2)}`),
      open: false
    },
    {
      name: "Piatti doccia",
      images: require.context("@/../public/images/Piatti doccia").keys().reverse().map(x => `/images/Piatti doccia/${x.substring(2)}`),
      open: false
    },
    {
      name: "Scale",
      images: require.context("@/../public/images/Scale").keys().reverse().map(x => `/images/Scale/${x.substring(2)}`),
      open: false
    },
    {
      name: "Vari",
      images: require.context("@/../public/images/Vari").keys().reverse().map(x => `/images/Vari/${x.substring(2)}`),
      open: false
    }
  ]

  scrollIntoGalleria(){
    setTimeout(() => {
        document.querySelector('#galleriaestesa').scrollIntoView({behavior: 'smooth'})
    }, 1)
  }

  mounted() {
    this.albums.forEach(async album => {
      setTimeout(() => {
        this.createFancybox(album.name)
      }, 2000)
    })
  }

  createFancybox(name: string): void {
    Fancybox.bind(`[data-fancybox="${name}"]`, {
      Carousel: {
        on: {
          "Carousel.*": e => console.log(e),
          change: (that) => {
            document.querySelector(`[data-fancybox="${name}"]:nth-of-type(${that.page+1})`)
                .scrollIntoView({behavior: "smooth"})
          },
        },
      },
    });
  }
}
