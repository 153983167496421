
import {Component, Vue} from "vue-property-decorator";

@Component
export default class App extends Vue {

  private currentSection = 'home'

  private scrollTo(id:string){
    document.querySelector(`#${id}`).scrollIntoView({behavior: "smooth"})
  }

  mounted(){
    this.scrollTo("home")
  }
}
