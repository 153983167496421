
import { Component, Vue } from 'vue-property-decorator';
import Chisiamo from "@/components/Chisiamo.vue";
import Trido from "@/components/Trido.vue";
import Dovesiamo from "@/components/Dovesiamo.vue";
import GalleriaEstesa from "@/views/GalleriaEstesa.vue"
import Contatti from "@/components/Contatti.vue";

@Component({components: {Contatti, GalleriaEstesa, Dovesiamo, Trido, Chisiamo}})
export default class Home extends Vue {
  mounted() {
    document.body.classList.remove("has-navbar-fixed-top")
    const callback = (entries: any[], _: any) => {
      entries.forEach(entry => {
        const {target} = entry;
        if (entry.intersectionRatio >= 0.25) {
          this.$emit('changeCurrentSection', target.id)
        }
      });
    };
    const observer = new IntersectionObserver(callback, {
      rootMargin: "0px",
      threshold: 0.25
    });
    document.querySelectorAll(".tridosection").forEach((i: any) => {
      observer.observe(i);
    })
  }
}
